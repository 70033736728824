.category-item-wrapper{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    padding: 15px;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 230px;
    &::before{
        content: "";
        background-color: #00000096;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:hover{
        .action-options{
            height: 50px;
            opacity: 1;
        }
    }

    .heading{
        color: #fff;
        position: relative;
        z-index: 1;
    }

    .action-options{
        position: relative;
        z-index: 1;
        height: 0px;
        overflow: hidden;
        opacity: 0;
        transition: all .5s ease;
    }
}

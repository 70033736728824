.store-item-cover {
  &:hover {
    .action-options {
      height: 50px;
      opacity: 1;
    }
  }

  .action-options {
    position: relative;
    z-index: 1;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
}

.qrDownloadWindow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}

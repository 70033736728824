.plans-cover-mx-wd {
  width: 100%;
  max-width: 1000px;
}
.plans-page-heading {
  h1 {
    font-size: 3rem;
    color: #000;
    span {
      color: #662d91;
    }
  }

  p {
    color: #afa2b9;
  }
}

.light-price-card {
  background-color: #f7f4f9 !important;
}

.pricing-plan-pro {
  box-shadow: 0 2px 40px 0 #cdcdcd8c;
}

.pricing-card {
  max-width: 350px;
  transition: all 0.6s;
  &:hover {
    box-shadow: 0 2px 40px 0 #cdcdcd8c;
    transform: translateY(-7px);
  }
  .pricing-plan-title {
    font-size: 20px;
    color: #000;
    margin-bottom: 11px;
    font-weight: normal;
  }

  .pricing-plan-cost {
    font-size: 50px;
    color: #000;
    font-weight: bold;
    margin-bottom: 29px;
  }

  .pricing-plan-features {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
    line-height: 2.14;
    margin-bottom: 35px;
    color: #303132;
  }
}

.congrats-card {
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  overflow: hidden;
  .icon-cover {
    background-color: #662d91;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: hidden;
  }
  .title {
    font-size: 35px;
    color: #000;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .caption {
    color: #0000004f;
    font-weight: bold;
    width: 100%;
    max-width: 400px;
  }
  .button-cover {
    max-width: 250px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  font-family: "Roboto", sans-serif !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff4ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

#root {
  width: 100%;
  min-height: 100vh;
}

.brand-bg {
  background-color: #662d91 !important;
}

.main-cover {
  width: 100%;
  min-height: 100vh;
}

.sm-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.fm-img {
  width: 100%;
  max-width: 700px;
}

@media (max-width: 767px) {
  .fm-img {
    max-width: 300px;
  }
}

.user-dropdown::after {
  position: absolute;
  top: 50%;
  right: 4px;
}

.zoom-hover {
  transition: all 0.5s ease;
}

.zoom-hover:hover {
  transform: scale(1.01);
  z-index: 1;
}

.page-bg-light {
  background-color: #f7f4f9;
}

.glass-form {
  background-color: #ffffff75;
}

.app-custom-btn-1 {
  background-color: #662d91;
  border: none;
  color: #f4e5ff;
  padding: 15px;
  font-size: 1rem;
  transition: all 0.5s ease-in;
}

.app-custom-btn-1:hover {
  background-color: #4b1e6c;
}

.app-custom-btn-1:disabled {
  cursor: not-allowed;
}

.payment-history-collapse-btn {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  color: #4b1e6c !important;
}

.invoice-container {
  background-color: #e7e9ed;
}

/* print style */
@media print {
  .navbar {
    display: none !important;
  }
  footer {
    display: none !important;
  }

  .brand-logo > img {
    width: 80px;
  }

  .invoice-container {
    background-color: #fff;
  }

  .invoice-container > .card {
    border: none;
    max-width: 100%;
  }
}

@page {
  size: A4 landscape;
  margin: 1mm 1mm 1mm 1mm;
}

@import "./components/header.scss";
@import "./components/StoreMobileFooter.scss";
@import "./components/login.scss";
@import "./components/plans.scss";
@import "./components/productItem.scss";
@import "./components/storeItem.scss";
@import "./components/cartItem.scss";
@import "./components/categoryItem.scss";

@import "./LandingPage.scss";
@import "./Admin.scss";

.landing-page {
  .welcome-message {
    height: auto;
    min-height: 100vh;
    h1 {
      max-width: 800px;
      font-size: 3.5rem;
    }
    p {
      max-width: 700px;
    }
    button {
      width: 100%;
      max-width: 200px;
    }
  }
}

$mdScreen: 768px;
.admin-nav-area {
  width: 100vw;
  max-width: 250px;
  background-color: #1f1f21;
  a {
    color: #edf0f5;
  }
}

.admin-content-area {
  width: 100%;
}

.admin-nav-area {
  ul {
    li {
      .nav-link.active {
        color: #a373c8;
      }
    }
  }
}

// new dashboard styles
.box-shadow-none {
  box-shadow: none !important;
}

.very-small {
  font-size: 0.7em;
}

.dashbaord-main {
  height: 100vh;
  .nav-area {
    width: 100%;
    @media only screen and (min-width: $mdScreen) {
      min-width: 260px;
      max-width: 260px;
    }

    background-color: #fff;
    .brand-title {
      color: #662d91;
    }

    .app-nav {
      .nav-item {
        transition: all;
        font-size: 0.9rem;
        &:hover {
          color: #000 !important;
        }
      }
      .nav-item.active {
        color: #000 !important;
      }

      .accordion-button {
        padding: 0;
        margin: 0;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        &::after {
          width: 1rem;
          height: 1rem;
          background-size: 1rem;
        }
      }
    }
  }
  .content-area {
    background-color: #fbf9f6;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .dashboard-header-section {
      background-color: #fbf9f6;
      #user-info-dropdown {
        &::after {
          display: none;
        }
      }
    }
  }
}

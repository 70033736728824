
.product-item-cover{
    &:hover{
        .action-options{
            height: 50px;
            opacity: 1;
        }
    }
    
    .action-options{
        position: relative;
        z-index: 1;
        height: 0px;
        overflow: hidden;
        opacity: 0;
        transition: all .5s ease;
    }

    .price{
        font-size: 17px;
        font-weight: 700;
        color: #c53b5a;
    }
}